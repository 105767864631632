import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class InitializerService {
  constructor(private readonly translationService: TranslateService) {}

  public initialize() {
    const lang = new URL(window.location.href).searchParams.get('language') || 'nl';
    return this.translationService.use(lang);
  }
}
