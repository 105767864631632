<div class="app">
  <header class="header">
    <img
      src="/assets/images/logos-{{ distribution | lowercase }}.svg"
      alt="{{ distribution }}"
      class="header__logo"
    />
  </header>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
