import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject, tap} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {GameEventType} from '../interfaces/game.interface';
import {VideoShare} from '../interfaces/video.interface';
import {ShareService} from '../services/share.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit, OnDestroy {
  public videoShare$: Observable<VideoShare>;
  public isHomeTeamActive = false;
  public locale: string;
  public slideInIsOpen = false;
  public showAlert = false;
  public shareLink: any;
  public shareId: string;
  public distribution: string;

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    private readonly shareService: ShareService,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
  ) {
    this.locale = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.locale = this.translateService.currentLang;

      this.updateShareLink();
    });
  }

  public ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.videoShare$ = this.shareService.getShare(params.shareId).pipe(
        tap(data => {
          this.isHomeTeamActive = data.gameEvent?.teamId === data.game?.homeTeam.id;
          this.distribution = this.shareService.distribution;
          this.shareId = data.game?.id;

          this.updateShareLink();
        }),
      );
    });
  }

  public updateShareLink() {
    this.shareLink = `https://${environment.shareHostname}.${
      this.shareService.bankingEntity
    }.be/${this.locale.toLowerCase()}/goalalert/start?matchid=${this.shareId}`;
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getEventIcon(eventType: GameEventType) {
    switch (eventType) {
      case GameEventType.RED_CARD:
        return 'soccer-func-match-red-card--multicolor';
      case GameEventType.SECOND_YELLOW_CARD:
        return 'soccer-func-match-yellowredcard--multicolor';
      case GameEventType.YELLOW_CARD:
        return 'soccer-func-match-yellowcard--multicolor';
      case GameEventType.VAR_REVIEW:
        return 'soccer-func-match-var';
      case GameEventType.FREEKICK:
      case GameEventType.SHOT:
        return 'soccer-func-match-shotontarget';
      case GameEventType.PENALTY_WON:
        return 'soccer-func-match-goal-penalty';
      case GameEventType.PENALTY_MISSED:
        return 'soccer-func-match-goal-penalty-missed--multicolor';
      default:
        return 'soccer-func-match-goal';
    }
  }
}
