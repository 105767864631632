import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';

import localeNl from '@angular/common/locales/nl-BE';
import localeFr from '@angular/common/locales/fr-BE';
import localeEn from '@angular/common/locales/en-BE';
import localeDe from '@angular/common/locales/de-BE';
registerLocaleData(localeNl);
registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeDe);

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ShareComponent} from './share/share.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpLoaderFactory} from './translations';
import {OverlayModule} from '@angular/cdk/overlay';
import {NotFoundComponent} from './not-found/not-found.component';
import {InitializerService} from './services/initializer.service';

@NgModule({
  declarations: [AppComponent, ShareComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OverlayModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (translationService: TranslateService) => translationService.currentLang,
      deps: [TranslateService],
    },
    InitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: InitializerService) => () => configService.initialize(),
      deps: [InitializerService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
