<ng-container *ngIf="videoShare$ | async as videoShare; else loading">
  <div class="video-share" *ngIf="videoShare.id; else error">
    <div class="video-share__top">
      <div class="container">
        <div *ngIf="videoShare.gameEvent" class="event">
          <i class="icon-{{ videoShare.gameEvent.type | lowercase }} event__icon"></i>

          <div class="event__title">
            {{ 'few_share_event_type_' + videoShare.gameEvent.type | lowercase | translate }}
          </div>

          <div class="score">
            <ng-container *ngIf="videoShare.gameEvent.score">
              {{
                (videoShare.gameEvent.score.goalType === 'ownGoal'
                  ? 'few_share_event_own_goal_by'
                  : 'few_share_event_by'
                )
                  | translate
                    : {
                        name:
                          videoShare.gameEvent.detail.player.firstname +
                          ' ' +
                          videoShare.gameEvent.detail.player.lastname,
                        team: this.isHomeTeamActive
                          ? videoShare.game.homeTeam.name
                          : videoShare.game.visitorTeam.name,
                        score:
                          videoShare.gameEvent.score.homeScore +
                          '-' +
                          videoShare.gameEvent.score.visitorScore
                      }
              }}
            </ng-container>
          </div>

          <div class="event__time">{{ videoShare.gameEvent.minute }}</div>
        </div>
        <div class="video image-wrapper">
          <img [src]="videoShare.image" [alt]="videoShare.title" />

          <button
            class="video__play"
            (click)="slideInIsOpen = !slideInIsOpen"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
          >
            <i class="icon-play"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="video-share__bottom">
      <div class="container container--flex">
        <div class="match-details">
          <div class="team">
            <img
              src="{{ videoShare.game.homeTeam.logoUrl }}"
              alt="{{ videoShare.game.homeTeam.name }}"
              class="team__logo"
            />
            <span class="team__name">{{ videoShare.game.homeTeam.name }}</span>
          </div>
          <div class="match-details__date">
            {{ videoShare.game.startDate | date : 'EE dd-LL' : undefined : locale + '-BE' }}
            <div
              class="match-details__status"
              [ngClass]="{'match-details__status--ongoing': videoShare.game.status === 'ONGOING'}"
            >
              {{
                (videoShare.game.status === 'ONGOING'
                  ? 'few_share_event_ongoing'
                  : 'few_share_event_finished'
                ) | translate
              }}
            </div>
          </div>
          <div class="team">
            <img
              src="{{ videoShare.game.visitorTeam.logoUrl }}"
              alt="{{ videoShare.game.visitorTeam.name }}"
              class="team__logo"
            />
            <span class="team__name">{{ videoShare.game.visitorTeam.name }}</span>
          </div>
          <div class="match-details__button">
            <!-- @TODO: https://icapps.atlassian.net/browse/GOAL-857 -->
            <a [href]="shareLink" type="button" class="btn btn--block">
              {{
                (videoShare.game.status === 'ONGOING'
                  ? 'few_share_event_button_ongoing'
                  : 'few_share_event_button_finished'
                ) | translate
              }}
            </a>
            <p>{{ 'few_share_disclaimer_' + distribution | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="body"
      [cdkConnectedOverlayOpen]="slideInIsOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayPositions]="[
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'top'
        }
      ]"
    >
      <div class="slide-in">
        <button class="slide-in__close" (click)="slideInIsOpen = false"
          ><i class="icon-close"></i
        ></button>

        <h3 class="slide-in__title">
          {{ 'few_share_event_activate_title' | translate }}
        </h3>

        <p
          class="slide-in__text"
          [innerHTML]="'few_share_event_activate_text_' + distribution | translate"
        >
        </p>

        <!-- @TODO: https://icapps.atlassian.net/browse/GOAL-857 -->
        <a [href]="shareLink" class="btn btn--block">{{
          'few_share_activate_button' | translate
        }}</a>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #loading>
  <div class="container">
    <div class="loader">
      <div class="loader__icon">
        <i class="icon-goal_scored"></i>
      </div>
      <div class="loader__message">
        {{ 'few_share_loading' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <div class="container">
    <h1>{{ 'few_share_error_title' | translate }}</h1>

    <p>{{ 'few_share_error_text' | translate }}</p>
  </div>
</ng-template>
