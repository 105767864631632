import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {catchError, Observable, of} from 'rxjs';
import {VideoShare} from '../interfaces/video.interface';
import {environment} from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  public bankingEntity = 'kbc';
  public distribution = 'kbc';

  constructor(
    private readonly http: HttpClient,
    private readonly translationService: TranslateService,
  ) {}

  public getShare(videoId: string): Observable<VideoShare> {
    return this.http
      .get<VideoShare>(`${environment.apiBaseUrl}/api/video/share/${videoId}`, {
        params: {
          token: environment.apiToken,
          language: this.translationService.currentLang.toUpperCase(),
        },
      })
      .pipe(
        catchError(() => {
          return of({} as VideoShare);
        }),
      );
  }
}
